const userManual = {
  getLink (module) {
    let link = ''
    if (module === 'CASHBOOK') {
      link = 'cashBook'
    } else if (module === 'RESERVATION_CONFIRM_INVOICE') {
      link = 'reservation/invoice.html'
    } else if (module === 'RESERVATION_SETTINGS') {
      link = 'reservation/options.html'
    } else if (module === 'RESERVATION_FISCAL') {
      link = 'fiscalization'
    }  else if (module === 'RESERVATION_INVOICE') {
      link = 'reservation/invoice.html'
    } else if (module === 'GUEST_REGISTRATION_FORM') {
      link = 'reservation/guestSheet.html'
    }
    return `/userManual/guide/${link}`
  }
}
export default userManual
