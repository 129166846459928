import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/scss/style.scss'
import './assets/scss/summernote-lite.css'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from 'axios'
import i18n from '@/i18n'
import helper from './helper/_helpers'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
import VCalendar from 'v-calendar'
import vSelect from 'vue-select'
import VeeValidate from 'vee-validate'
import validationEnglishMessages from 'vee-validate/dist/locale/en'
import validationGermanMessages from 'vee-validate/dist/locale/de'
import vueCountryRegionSelect from 'vue-country-region-select'
import VueProgressBar from 'vue-progressbar'
import IdleVue from 'idle-vue'
import VueTheMask from 'vue-the-mask'
import VueApexCharts from 'vue-apexcharts'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2'
import userManual from '@/helper/userManual';
window.$ = window.jQuery = require('jquery')

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueTheMask)
Vue.use(vueCountryRegionSelect)
const validateConfig = {
  errorBagName: 'veeErrors', // change if property conflicts
  fieldsBagName: 'veeFields',
  i18n: i18n, // the vue-i18n plugin instance
  dictionary: {
    en: validationEnglishMessages,
    de: validationGermanMessages
  },
  i18nRootKey: 'validations' // the nested key under which the validation messages will be located
}
Vue.use(TawkMessengerVue, {
  propertyId: '5fd1c487a1d54c18d8f23811',
  widgetId: '1g0k39e0l'
})
Vue.use(VeeValidate, validateConfig)
Vue.component('v-select', vSelect)
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
})
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
let apiUrl = ''
let domain = ''
if (location.hostname === 'localhost') {
  apiUrl = 'http://localhost/works/HosBookEngine/api/'
  domain = 'test'
} else if (location.hostname === 'test.hosbooking.com') {
  apiUrl = 'https://testapi.hosbooking.com/'
  domain = 'test'
} else {
  apiUrl = 'https://api.hosbooking.com/'
  domain = location.hostname.slice(0, location.hostname.indexOf('.'))
}

axios.defaults.baseURL = apiUrl
axios.interceptors.request.use(function (config) {
  config.headers['Accept-Language'] = i18n.locale
  config.headers.Token = 'admin'
  config.headers.Domain = domain
  config.headers.Authorization = localStorage.getItem('HosAdminAuthToken')
  return config
}, function (error) {
  return Promise.reject(error)
})

Vue.prototype.$http = axios
Vue.prototype.$helper = helper
Vue.prototype.$domain = domain
Vue.prototype.$userManual = userManual
Vue.prototype.$apiUrl = apiUrl

const moment = require('moment')
if (i18n.locale === 'de') {
  require('moment/locale/de')
} else if (i18n.locale === 'es') {
  require('moment/locale/es')
}
const swalOptions = {
  confirmButtonColor: '#00b74a',
  cancelButtonColor: '#ff7674',
  reverseButtons: true,
  keydownListenerCapture: true,
  heightAuto: false
}
Vue.use(VueSweetalert2, swalOptions)
Vue.use(require('vue-moment'), {
  moment
})
const progressBarOptions = {
  color: '#ffeb3b',
  failedColor: '#f93154',
  thickness: '5px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
}
Vue.use(VueProgressBar, progressBarOptions)
const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 3600000,
  startAtIdle: false
})
new Vue({
  router,
  i18n: i18n,
  store,
  render: h => h(App)
}).$mount('#app')
