import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: {},
    updateVersion: 0,
    walkInStartDate: '',
    settings: null,
    updates: [],
    sources: [],
    client: null,
    fullWidth: false,
    sideBarState: '',
    mobileOpened: false, // check and remove
    appSidebarClass: '', // check and remove
    appMenuBarClass: '', // check and remove
    houses: {
      state: '',
      message: '',
      list: []
    },
    fistHouseId: '',
    walkInState: 'new',
    WalkInId: '',
    roomRemovedDate: '',
    totalUnReadMails: 0,
    mailBoxCounts: []
  },
  mutations: {
    setTotalNewMails (state, count) {
      state.totalUnReadMails = count
    },
    setMailCounts (state, array) {
      state.mailBoxCounts = array
    },
    setWalkInState (state, data) {
      state.walkInState = data
    },
    setWalkInId (state, data) {
      state.WalkInId = data
    },
    setRoomRemoved (state, data) {
      state.roomRemovedDate = data
    },
    setSideBarState (state, data) {
      state.sideBarState = data
    },
    setWalkInFromDate (state, data) {
      state.walkInStartDate = data
    },
    setLoggedUser (state, data) {
      state.user = data
    },
    setHouseLoading (state) {
      state.houses.state = 'loading'
    },
    setHouseError (state, data) {
      state.houses.state = 'error'
      state.houses.message = data.error
    },
    setHouseList (state, data) {
      state.houses.state = 'success'
      state.houses.message = ''
      state.houses.list = data.list
      state.fistHouseId = data.list[0].id
    },
    setSettings (state, data) {
      state.settings = data.settings
      state.client = data.client
      state.sources = data.sources
    },
    setUpdateInfos (state, data) {
      state.updates = data.updates
      state.updateVersion = data.updateVersion
    },
    setSettingsAccountCodes (state, data) {
      state.settings.accounting = data
    },
    setSidebarHideClass (state) {
      state.appSidebarClass = 'mobile hide'
      state.appMenuBarClass = 'd-block'
    },
    setSidebarShowClass (state) {
      state.appSidebarClass = ''
      state.appMenuBarClass = 'd-none'
    },
    openCloseMenu (state) {
      if (state.mobileOpened) {
        state.mobileOpened = false
        state.appSidebarClass = 'mobile hide'
      } else {
        state.mobileOpened = true
        state.appSidebarClass = 'mobile show'
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
export default store
